import "$styles/index.css";
import * as Turbo from "@hotwired/turbo";
import { Application } from "@hotwired/stimulus";

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}";

window.Stimulus = Application.start();

import controllers from "./controllers/**/*.{js,js.rb}";
Object.entries(controllers).forEach(([filename, controller]) => {
  if (filename.includes("_controller.") || filename.includes("-controller.")) {
    const identifier = filename
      .replace("./controllers/", "")
      .replace(/[_-]controller\..*$/, "")
      .replace("_", "-")
      .replace("/", "--");

    Stimulus.register(identifier, controller.default);
  }
});
