import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["guest", "user"];

  connect() {
    const isSignedIn = this.getCookieValue("signed_in") === "1";
    if (isSignedIn) {
      this.guestTargets.forEach((e) => e.remove());
      this.userTargets.forEach((e) => (e.style.display = "inline"));
    } else {
      this.userTargets.forEach((e) => e.remove());
    }
  }

  getCookieValue(name) {
    return (
      document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)")?.pop() ||
      ""
    );
  }
}
