import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static classes = ["activeContent", "activeControl"];
  static targets = ["content", "control"];

  showContent(event) {
    event.preventDefault();

    for (const control of this.controlTargets) {
      control.classList.remove(this.activeControlClass);
    }

    event.currentTarget.classList.add(this.activeControlClass);

    for (const content of this.contentTargets) {
      if (
        content.dataset.switcherContentId ===
        event.currentTarget.dataset.switcherContentId
      ) {
        content.classList.add(this.activeContentClass);
      } else {
        content.classList.remove(this.activeContentClass);
      }
    }
  }
}
