import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    clientToken: String,
    retainKey: String,
    sandbox: Boolean,
  };

  connect() {
    if (window.Paddle) {
      this.handleOnload();
    } else {
      const script = document.createElement("script");
      script.addEventListener("load", this.handleOnload.bind(this), {
        once: true,
      });
      script.src = "https://cdn.paddle.com/paddle/v2/paddle.js";

      this.scriptElement = document.head.appendChild(script);
    }
  }

  disconnect() {
    this.scriptElement?.remove();
  }

  handleOnload() {
    if (this.sandboxValue) {
      Paddle.Environment.set("sandbox");
    }

    const pwAuth = this.retainKeyValue ? this.retainKeyValue : null;

    Paddle.Setup({
      token: this.clientTokenValue,
      pwAuth,
      pwCustomer: {},
    });
  }
}
